import * as React from 'react';
// import * as PropTypes from 'prop-types';
import { Router  } from 'react-router-dom';
import config from '../app-config';
import { userTypeEnums} from '../features/shared/enums'
import {Footer} from './common/footer';
import AccountLockedModal from './account-locked-modal';
import moment from 'moment';
require("@webcomponents/webcomponentsjs/webcomponents-bundle");
require("@webcomponents/webcomponentsjs/custom-elements-es5-adapter");

require("@pearson-ux/footer");

import Nav from './common/nav';

import Routes, { breadcrumb as Breadcrumb } from './routes';
import { AuthProfile } from 'models/auth';
import User  from 'models/user';
import AATeam,{ProxyAATeamMember}  from 'models/team';
import AccessDeniedModal from '../features/shared/access-denied-modal';
import InformationModalGeneric from '../features/shared/information-modal-generic';
import { browserVersions} from '../features/shared/constants'
import Auth from '../actions/auth/auth';
import { detect } from 'detect-browser';
import { Header } from 'ukas.uux.react-components';
import { history } from './router';
const auth = new Auth();
import UserDataProvider  from "../shared/context-api/provider";

const PearsonElementsSdk = require('@pearson-components/modal');

import SelectTeamMember from './select-team-member/select-team-member.container.component';
import { auditUserLogout } from '../helpers/audit-user-helper';
import { AssessmentAssociate, Nullable } from 'models/assessment-associate';

export interface DispatchProps {
  signin?: () => void;
  singout?: () => void;
  loadSampleStandardsVerificationRequests?: (assessmentAssociateId: string) => Promise<void>;
  getAATeamLeadDetails?: (assessmentAssociateId: string, academicYear: string) => Promise<void>;
  getLoggedInUserDetails?: (forgeRockId: string) => Promise<void>;
  getEnv?: () => void;
  selectAATeam?: (selectedtedAATeamId: string) => void;
  updateProxyAATeamMember?: (proxyAATeamMember: ProxyAATeamMember) => void;
  validateUserPermissions?: (family_name: string, loggedInUserPermissionIds: number[]) => Promise<void>;
  getAssessmentAssociateDetails?: (forgeRockId: string) => Promise<void>;
}


export interface AppProps {
  loading?: boolean;
  isAuthenticated?: boolean;
  profile?: AuthProfile;
  assessmentAssociateId?: string;
  proxyAAName? : string;
  isAATeamLead?: boolean;
  proxyAATeamMemberName?: string;
  aaTeams?: AATeam[];
  selectedAATeamName:string;
  loggedInUser?: User,
  userPermissionValidated:boolean,
  showCreateMenu:boolean,
  proxyAATeamMember?: ProxyAATeamMember
  assessmentAssociate: Nullable<AssessmentAssociate>
}

interface State {
  showAccessDeniedModal: boolean;
  showSelectAATeamModal: boolean;
  isAccountLocked: boolean;
  showTeamMemberModal: boolean;
  teamName: string;
  showBrowserCompatibileModal: boolean;
  isSessionAvailable: boolean;
}

type Props = DispatchProps & AppProps;

export class App extends React.Component<Props, State> {
  timer: NodeJS.Timeout;
  checkSessionTime: NodeJS.Timeout;
  checkBrowserValidTime: NodeJS.Timeout;
  constructor(props: Props) {
    super(props);
    
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.helpIconRedirct = this.helpIconRedirct.bind(this);
    
    this.state = {
      showAccessDeniedModal: false,
      showSelectAATeamModal: false,  
      isAccountLocked: false,
      showBrowserCompatibileModal: false,
      isSessionAvailable: true,
      showTeamMemberModal :false,
      teamName: '',
      // showAccessDeniedModal: !this.props.userPermissionValidated
    }
    
    this.onCloseSessionExpiredModal = this.onCloseSessionExpiredModal.bind(this);
    this.onCloseAccessDeniedModal = this.onCloseAccessDeniedModal.bind(this);
    this.teamsListChangeHandler = this.teamsListChangeHandler.bind(this);
    this.cancelTeamMemberModalHandler = this.cancelTeamMemberModalHandler.bind(this);
    this.onCloseBrowserCompatibleModal = this.onCloseBrowserCompatibleModal.bind(this);
  }

  render() {
    
    const isAuthenticated = this.props.isAuthenticated;
    let signedInUser = "";
    if (this.props.assessmentAssociate) signedInUser = `${this.props.assessmentAssociate.firstName} ${this.props.assessmentAssociate.surname}`; 
    const text = {
      headerTitle: 'Access Denied',
      bodyText: '',
      closeButtonSRText: 'Close',
      modalSaveButtonText: 'Ok',
      modalCancelButtonText: 'Close',
     
    };
    const sessionText = {
      headerTitle: "Session Expiry",
      bodyText: "",
      closeButtonSRText: "Close",
      modalSaveButtonText: "Ok",
      modalCancelButtonText: "Close"
    }

    const browserCompatibleText = {
      headerTitle: "You may need to upgrade your browser",
      bodyText: "",
      closeButtonSRText: "Close",
      modalSaveButtonText: "Ok",
      modalCancelButtonText: "Ok"
    };
    const textSelectAATeamMember = {
      headerTitle: 'Select a Team Member',
      bodyText: '',
      closeButtonSRText: 'Close',
      modalSaveButtonText: 'Ok',
      modalCancelButtonText: 'Close',
     
      };
    return (
 
      <div>
        <UserDataProvider>
      <Router history={history}>
        
          <div>
            <Header
              signedIn={isAuthenticated} 
              signedInUser={signedInUser} 
              onSignIn={this.login} 
              onSignOut={this.logout}
              onShowHelp={this.helpIconRedirct}
              showHelp
            />
         
            <Nav 
              loading={this.props.loading == null ? false : this.props.loading}
              isAuthenticated={isAuthenticated!}   
              userType={this.props.profile?this.props.profile.family_name:'null'}  
              proxyUserName={''+this.props.proxyAAName} 
              showCreateMenu={this.props.showCreateMenu}
              isAATeamLead={this.props.isAATeamLead?this.props.isAATeamLead:false} 
              aaTeams={this.props.aaTeams?this.props.aaTeams:[]} 
              proxyAATeamMemberName={this.getProxyAATeamMemberName()}
              onAATeamSelect={this.onAATeamSelect}
              
            />
            {/* <div className="test" ></div> */}
            <div className="app">
              <Breadcrumb />
              <Routes />
              <div className="pe-strategy__centered--small-gap">&nbsp;</div>
            </div>            
          </div>
        </Router>
           
        <Footer />
        </UserDataProvider>
        {/* <div className="footer">
          <pearson-footer />
        </div> */}
            {/* <style>{sessionExpiryModalCss}</style> */}
            <PearsonElementsSdk.Modal
                isShown={!this.state.isSessionAvailable}
                text={sessionText}
                footerVisible={false}
                cancelBtnHandler={this.onCloseSessionExpiredModal}
            >
                <InformationModalGeneric
                    closeBtnHandler={this.onCloseSessionExpiredModal}
                    infoMsgRender={this.sessionExpirynMsgRender}
                    closeBtnText={"Ok"}
                />
           </PearsonElementsSdk.Modal>
            {/**** END Session Expired Modal ****/}

          <PearsonElementsSdk.Modal
          isShown={
            this.state.showBrowserCompatibileModal
          }
          text={browserCompatibleText}
          footerVisible={false}
          cancelBtnHandler={this.onCloseBrowserCompatibleModal}
        >
          <InformationModalGeneric 
            closeBtnHandler={this.onCloseBrowserCompatibleModal}
            infoMsgRender = {this.getBrowserCompatibleMsg}
          />
        </PearsonElementsSdk.Modal>
        
          <PearsonElementsSdk.Modal
          isShown={this.state.isAccountLocked}
          text={text}
          footerVisible={false}
          cancelBtnHandler={this.onCloseAccessDeniedModal}
        />
          <PearsonElementsSdk.Modal
          isShown={this.state.isAccountLocked}
          text={text}
          footerVisible={false}
          cancelBtnHandler={this.onCloseAccessDeniedModal}
        >
          <AccountLockedModal
          isAccountLocked = {this.state.isAccountLocked}
            cancelBtnHandler={this.onCloseAccessDeniedModal}
            userType={(this.props.profile && this.props.profile.family_name) ? this.props.profile.family_name : ''}
          />
        </PearsonElementsSdk.Modal>
        <PearsonElementsSdk.Modal
          isShown={this.state.showAccessDeniedModal && !this.state.isAccountLocked}
          text={text}
          footerVisible={false} 
          cancelBtnHandler={this.onCloseAccessDeniedModal}
        >
            <AccessDeniedModal   
              cancelBtnHandler={this.onCloseAccessDeniedModal} 
              userType={(this.props.profile && this.props.profile.family_name)?this.props.profile.family_name:''}
            />
        </PearsonElementsSdk.Modal>
        {/* Select AA Team Modal */}
        <PearsonElementsSdk.Modal
          isShown={this.state.showSelectAATeamModal}
          text={textSelectAATeamMember}
          footerVisible={false} 
          cancelBtnHandler={this.onCloseShowSelectAATeamModal}
        >
            <SelectTeamMember {...this.props as any} teamName={this.state.teamName} closeEvent= {this.cancelTeamMemberModalHandler} />
        </PearsonElementsSdk.Modal>
        {/* Select AA Team Modal-END */}

          {/* <PearsonElementsSdk.Modal  id="search"
            isShown={this.state.showTeamMemberModal} 
            text={teamMembertext}
            footerVisible={true}
            cancelBtnHandler={this.cancelTeamMemberModalHandler}
            successBtnHandler={this.successTeamMemberModalHandler} saveBtnId="disableSuccessBtn" >
              <SelectTeamMember {...this.props as any} teamName={this.state.teamName} />
          </PearsonElementsSdk.Modal> */}
        
      </div>
  
    );
  }

  checkBrowserCompatibility = () => {
    const browserDetect = detect();
    const browserName = browserDetect ? browserDetect.name : "";
    const browserVersion = browserDetect ? browserDetect.version : "";

    let iscompatible = false;
    if (browserName && browserName.toLowerCase().indexOf("msie") !== -1 || browserName && browserName.toLowerCase() === "edge") {
      iscompatible = false;
    } else if (browserName.toLowerCase().indexOf("chrome") !== -1 && 
    browserVersion && parseInt(browserVersion,10) >= browserVersions.CHROME ) {
      iscompatible = true;
    } else if (browserName && browserName.toLowerCase().indexOf("firefox") !== -1 &&
    browserVersion && parseInt(browserVersion,10) >= browserVersions.FIREFOX) {
      iscompatible = true;
    } else if (browserName && browserName.toLowerCase().indexOf("safari") !== -1 &&
    browserVersion && parseInt(browserVersion,10) >= browserVersions.SAFARI) {
      iscompatible = true;
    } else if (browserName && browserName.toLowerCase().indexOf("edge-chromium") !== -1 &&
    browserVersion && parseInt(browserVersion, 10) >= browserVersions.EDGECHROMIUM) {
      iscompatible = true;
    }
    return this.setState({showBrowserCompatibileModal : !iscompatible})
  }
  
  getProxyAATeamMemberName = () =>{
    let getTeamMemberName:any = 'My Requests';
    if(localStorage.getItem('isTeamMemberaa') != null){
     getTeamMemberName = localStorage.getItem('isTeamMemberaa')+' '+localStorage.getItem('isTeamMembername') 
    }
    return getTeamMemberName;
  }

  onAATeamSelect = (
    teamName: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    if(teamName && teamName.length){    
      this.props.selectAATeam!(teamName);
      this.setState({showSelectAATeamModal:true})
    } else{
      const proxyAATeamMember = {
        teamName: '',
        firstName: 'My ',
        lastName: 'Requests',
        assessmentAssociateId: parseInt(this.props.assessmentAssociateId+'',10),
        aaNumber:''
      }
      this.props.updateProxyAATeamMember!(proxyAATeamMember)
      this.props.loadSampleStandardsVerificationRequests!(this.props.assessmentAssociateId+ '');
      localStorage.removeItem('isTeamMember');
      localStorage.removeItem('isTeamMemberaa');
      localStorage.removeItem('isTeamMembername');
      history.push('/sample-requests');   
    }
  }

  onCloseShowSelectAATeamModal = (event: React.UIEvent<HTMLSpanElement>) => {
    this.setState({showSelectAATeamModal: false})
  }

  getBrowserCompatibleMsg(){
    return (<div>
      <br/>
      <div>
      This application is best viewed through Safari 12, Chrome 76, Firefox 69, Edge-Chromium 80 or any higher version of these browsers. 
      <br />
       You may have difficulty using some of the features. For a better experience, please install any of the recommended browser versions.
      </div>
      <br/>
    </div>);
  }

  onCloseBrowserCompatibleModal(){
    this.setState({showBrowserCompatibileModal:false})
  }
  onCloseAccessDeniedModal = (event: React.UIEvent<HTMLSpanElement>) => this.logout();
  
  onCloseSessionExpiredModal = () => {
    // this.logout();
    auth.signout();
  }
  
  sessionExpirynMsgRender(){
    return (<div>
      <p>
      Your browser session is going to expire in next 2 minutes. To continue using the application, you are required to login again.
        <br /><br />Please click <strong>Ok</strong> to go to the login page.
      </p>
    </div>);
  }

  teamsListChangeHandler(event: React.FormEvent<HTMLSelectElement>) {
    const teamName = (event.target as HTMLInputElement).value;
    console.log("TEAM NAME--", teamName);
    this.setState({showTeamMemberModal:true,teamName});
  }

  cancelTeamMemberModalHandler =()=>{
    
    this.setState({showSelectAATeamModal:false});
    // return this.props.history.push('/sample-requests');
  }

  successTeamMemberModalHandler =()=>{
    this.setState({showTeamMemberModal:false});
    // return this.props.history.push('/');
  }
  // componentWillReceiveProps = () => {
    // this.setState({showAccessDeniedModal: !this.props.userPermissionValidated});
    // console.log("SHOW ACCESS Denied = componentWillReceiveProps",this.props.userPermissionValidated)     
  // }
  checkSession = () => {
    // const accessToken =auth.getAccessToken();
      try{
          if(!auth.isAuthenticated()) {
          clearInterval(this.checkSessionTime);
          this.setState({isSessionAvailable : false, showBrowserCompatibileModal :false})
          setTimeout(()=>{auth.signout()}, 110000);
        } 
      } catch(e){
        console.log(e);
      }

  }
  componentDidMount() { 

    // const userTypeObj = auth.getProfile()
    // // console.log("payload",userType)
    // const userType = userTypeObj.family_name;
    // // getHistory().push('/Maintenance')
    // console.log((userType === 'External'))
    
    // if(userType === 'External') { 
    //   getHistory().push('/Maintenance');
    // }

    this.checkBrowserValidTime =setTimeout(()=>{this.checkBrowserCompatibility()},2000);
    this.checkSessionTime = setInterval(()=>{this.checkSession()},60000);
    const isAuthenticated = this.props.isAuthenticated!;
    setTimeout(() => this.getCABSUserDetails(),500); 
    // console.log(":::",this.props.userPermissionValidated)

    let username = '';
    if (this.props.profile) {
      username = this.props.profile.name;
    }

    this.setHeader(isAuthenticated, username);

    document.addEventListener('oAppHeader.login', e => {
      this.login();
    });

    document.addEventListener('oAppHeader.logout', e => {
      this.logout();
    });

    document.addEventListener('oAppHeader.help.toggle', e => {
      this.helpIconRedirct();
    });


   
      this.timer = setTimeout(() => { 
    if((this.props.profile && this.props.profile.profile) &&
    this.props.getAssessmentAssociateDetails)  {
      this.props.getAssessmentAssociateDetails(this.props.profile.profile);
    }

    if( this.props.profile && this.props.profile.profile && 
      this.props.getLoggedInUserDetails && 
      this.props.profile.family_name.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase() ){
    
      const proxyAAId = localStorage.getItem('proxyAAId')
      if(proxyAAId && proxyAAId !== "0")
      {
        // load the proxy SV data here
        this.props
          .loadSampleStandardsVerificationRequests!(proxyAAId+'')
          .then() 
          .catch(err => {
            if (err && err.message) {
              console.log("error message",err.message);
            }
          });
      }
    }
    if( this.props.profile && this.props.profile.profile && 
      this.props.getLoggedInUserDetails && 
      this.props.profile.family_name.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase() ){

      const proxyAAId = localStorage.getItem('proxyAAId')
      if(proxyAAId && proxyAAId !== "0")
      {
        // load the proxy SV data here
        this.props
          .loadSampleStandardsVerificationRequests!(proxyAAId+'')
          .then() 
          .catch(err => {
            if (err && err.message) {
              console.log(err.message);
            }
          });
      }
    }
  },1000)
  }

  componentWillReceiveProps(nextProps : any) {

    const isAuthenticated = this.props.isAuthenticated!;
    if((!(this.props.profile && this.props.profile.profile) && (nextProps.profile && nextProps.profile.profile)) &&
      this.props.getAssessmentAssociateDetails)  {
        this.props.getAssessmentAssociateDetails(nextProps.profile.profile);
      }

    if (this.props.profile && (!this.props.assessmentAssociateId && nextProps.assessmentAssociateId)) {
      // if (this.props.assessmentAssociateId !== undefined) {
        // if (this.props.profile.profile !== undefined) {
        if (isAuthenticated) {
          
          this.getCABSUserDetails();
          this.setState({showAccessDeniedModal: !this.props.userPermissionValidated}); 
          
          if( nextProps.profile && nextProps.profile.profile && 
              this.props.getLoggedInUserDetails && 
              nextProps.profile.family_name.toUpperCase() === userTypeEnums.INTERNAL_USER.toUpperCase() ){
  
            const proxyAAId = localStorage.getItem('proxyAAId')
            // load the proxy SV data here
            this.props
              .loadSampleStandardsVerificationRequests!(proxyAAId+'')
              .then() 
              .catch(err => {
                if (err && err.message) {
                  console.log(err,err.message);
                }
              });
  
          } 
          else if(  nextProps.profile && nextProps.profile.profile && 
                      this.props.getLoggedInUserDetails && nextProps.loggedInUser &&
                      nextProps.loggedInUser.isUserPermissionValid && 
                      nextProps.profile.family_name.toUpperCase() === userTypeEnums.EXTERNAL_USER.toUpperCase() ){ 
                        
                        const isTeamMember = localStorage.getItem('isTeamMember')
                        if(!isTeamMember){
                          // load the default data here
                          this.props
                            .loadSampleStandardsVerificationRequests!(nextProps.assessmentAssociateId)
                            .then()
                            .catch(err => {
                              if (err && err.message) {
                                console.log(err.message);
                              }
                            });
                        }else{
                          let teamMemberAAno:any = localStorage.getItem('isTeamMemberaa');
                          console.log("teamMemberAAno", teamMemberAAno)
                          teamMemberAAno = parseInt(teamMemberAAno,10)
                          
                          this.props
                          .loadSampleStandardsVerificationRequests!(teamMemberAAno)
                          .then()
                          .catch(err => {
                            if (err && err.message) {
                              console.log(err)
                              console.log(err.message);
                            }
                          });
                        }
  
          }
        }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isAuthenticated && !prevProps.isAuthenticated) {
      if (this.props.assessmentAssociateId !== undefined) {
        this.getCABSUserDetails();
        if(this.state.showAccessDeniedModal === false){
          // this.setState({showAccessDeniedModal: !this.props.userPermissionValidated}); 
          // console.log("SHOW ACCESS Denied = componentDidUpdate", this.state.showAccessDeniedModal) 
        }
      }
      if (this.props.profile !== prevProps.profile) {
        let username = '';
        if (this.props.profile) {
          username = `${this.props.profile.name}`;
        }
        this.setHeader(this.props.isAuthenticated, username); 
      }
      if(this.props.getEnv){
        this.props.getEnv();
      }
    }
  }

  setHeader = (isAuthenticated: boolean, username: string) => {
    // const header = new AppHeader(
    //   document.getElementById('header'),
    //   isAuthenticated
    //     ? {
    //       mode: 'Basic',
    //       user: { given_name: username },
    //       menuItems: [],
    //       links: {
    //         termsOfUse: config.HELP_LINKS.TERMS_OF_USE,
    //         privacyPolicy: config.HELP_LINKS.PRIVACY_POLICY
    //       }
    //     }
    //     : {
    //       mode: 'Signed Out',
    //       showLoginControls: true
    //     }
    // );
    const header =true;
    return header;
  };

  login() {
    this.props.signin!();
  }

  logout() {
    auditUserLogout().finally(() => {
      auth.signout();
    })
  }

  helpIconRedirct() {
    const redirectLink = this.props.profile!.family_name === "Internal" ? config.HELP_LINKS.HELP_INTERNAL : config.HELP_LINKS.HELP;
    window.open(redirectLink);
    if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
  }

  getCABSUserDetails(){
    if (
      this.props.profile &&
      this.props.profile.profile &&
      this.props.getLoggedInUserDetails
    ) {
      this.props
        .getLoggedInUserDetails(this.props.profile.profile)
        .then(() => {
          const currentDate = moment();

          if (this.props.loggedInUser) {
            const lockedUntil = moment(this.props.loggedInUser.lockedUntil);
            if (moment(lockedUntil).isAfter(currentDate)) {
              this.setState({ isAccountLocked: true });
            }
          }

          if(this.props.loggedInUser){
            this.setState({
              showAccessDeniedModal: !this.props.loggedInUser
                .isUserPermissionValid
            })
          }
        });
    }    
  }
}
export default App;